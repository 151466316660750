<template>
  <div>
      <graph ref="graph"></graph>
      <br>
  <vx-card>
    <vs-table
        v-model="selected"
        
        pagination
        max-items="10"
        search
        :data="listAll" 
        noDataText="Aucun résultat"
        >

            <template slot="header">
                <h3 >Perf. Équipe</h3>
            </template>

            <template slot="thead">
                <vs-th sort-key="coach">Coach</vs-th>
                <vs-th sort-key="nbVente">Transaction</vs-th>
                <vs-th sort-key="facture">Facture</vs-th>
                <vs-th sort-key="avoir">Avoir</vs-th>
                <vs-th sort-key="total">Total</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].coach">
                        {{ data[indextr].coach }}
                    </vs-td>
                    <vs-td :data="data[indextr].nbVente">
                        {{ data[indextr].nbVente }}
                    </vs-td>
                    <vs-td :data="data[indextr].facture">
                        {{ data[indextr].facture }}
                    </vs-td>
                    <vs-td :data="data[indextr].avoir">
                        {{ data[indextr].avoir }}
                    </vs-td>
                    <vs-td :data="data[indextr].total">
                        {{ data[indextr].total.toFixed(devise.nb_decimal) }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
      </vx-card>
  </div>
</template>

<script>
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'
import graph from '@/components/stats/components/graphTeamComp.vue'

import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'

import BoxVente from '@/components/app/boxVente'
import cDate from '@/components/app/date'
import VxCard from '@/components/vx-card/VxCard.vue'

export default {
  components: {
        BoxVente,
        cDate,
        VxCard,
        graph,
    },
  name:'team',
  props:[
        'shared',
        // shared change bien, mais comment modifier celui du this !! 
    ],
    data(){
        return{
           
            terminal_uid :this.shared.terminal_uid,
            terminal_name:this.shared.terminal_name,
            terminal_list:this.shared.terminal_list,

            list_facture:this.shared.list_facture,
            selected:this.shared.selected,

            list_type:this.shared.list_type,

            list_date: this.shared.list_date,


            vente_seance:this.shared.vente_seance,
            vente_accesoire:this.shared.vente_accesoire,
            vente_ca_mensuel:this.shared.vente_ca_mensuel,
            vente_ca_annuel:this.shared.vente_ca_annuel,

            admin:this.shared.admin,
            listAll:[],

            devise: this.shared.devise,
        }
    },
    watch:{
        list_facture:function(){
            // console.log(" team --- list Facture ; ", this.list_facture)
            //this.filtreData();
            this.filtreData();
        },
        list_date:function(){
            //this.filtreData();
            ListRefresh(this);
        }
    },
    mounted:function()
	{
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
        this.RoleAdmin();
        ListRefresh(this);
        frefresh(this);
	},
  methods:{
        millier:Utils.Millier,
        //change terminal
        
        changeTerminal:function(elem)
        {
            this.terminal_uid=elem;
            //console.log(" parent termina changed ", this.terminal_uid)
            if(this.$route.params.uid != this.terminal_uid)
            {
                this.$router.push('/admin/facturesView/'+this.terminal_uid);
                this.list_facture = [];
                frefresh(this);
            }
            return;
        },
        
        log(){
            //console.log("this is the shared : ", this.shared, " and this data  ", this.$data);
        },
       listChange(/*event*/){
           //console.log(" recu : ", event);
       },
        changeDate:function(alldata){
           // console.log(" essai all data ", alldata.vente_ca_mensuel)
           // console.log(" parent date changed ", "actuel : ", alldata.list_date)
            this.transferInfo(alldata);
           // console.log(" after : ", this.list_date);
            frefresh(this)
           // console.log(" lst fact : ", this.list_facture)
           this.filtreData();
            
        },
        changeType(alldata){
            //console.log(" parent type changed ")
            this.transferInfo(alldata);
            frefresh(this)
        },
        refresh:function(){
            ListRefresh(this);
        },
        handleSelected()
        {
            //voir facture
            this.$router.push('/admin/invoice/'+this.selected.uid);
        },
        transferInfo(alldata){
            this.terminal_uid =alldata.terminal_uid,
            this.terminal_name=alldata.terminal_name,
            this.terminal_list=alldata.terminal_list,

            this.list_facture=alldata.list_facture,
            this.selected=alldata.selected,

            this.list_type=alldata.list_type,

            this.list_date= alldata.list_date;


            this.vente_seance=alldata.vente_seance,
            this.vente_accesoire=alldata.vente_accesoire,
            this.vente_ca_mensuel=alldata.vente_ca_mensuel,
            this.vente_ca_annuel=alldata.vente_ca_annuel,

            this.admin=alldata.admin
            this.devise = alldata.devise
        },

        RoleAdmin()
        {
            this.admin = false;
            //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },
        filtreData(){
            let listCoach=[];
            let listVente=[];
            let listFacture =[];
            let listAvoir=[];
            let listTotal=[];
            for(let i=0;i<this.list_facture.length; i++){
                let aux=this.list_facture[i];
                if (listCoach.includes( aux.vendor.first_name + " "+ aux.vendor.last_name)){
                    let coach = aux.vendor.first_name + " "+ aux.vendor.last_name;
                    //console.log(" existe nom ", listCoach.indexOf(coach));
                    let x = listCoach.indexOf(coach);
                    listVente[x]=listVente[x]+1;
                    if (aux.type=="Facture"||aux.type=="Reçu"){
                        listFacture[x]=listFacture[x]+1;
                    }
                    if(aux.type=="Avoir"){
                        listAvoir[x]=listAvoir[x]+1;
                    }
                    listTotal[x]=listTotal[x]+parseFloat(aux.total);
                }
                else{
                    let coach = aux.vendor.first_name + " "+ aux.vendor.last_name;
                    listCoach.push(coach);
                    listVente.push(1);
                    if (aux.type=="Facture"||aux.type=="Reçu"){
                        listFacture.push(1);
                    }
                    else{
                        listFacture.push(0);
                    }
                    if(aux.type=="Avoir"){
                        listAvoir.push(1);
                    }
                    else{
                        listAvoir.push(0);
                    }
                    listTotal.push(parseFloat(aux.total));
                }
            }
            // console.log(" list coach : ", listCoach, " list vente : ", listVente, " list facture : ", listFacture)
            // console.log(" list avoir : ", listAvoir, " list total ", listTotal)
            //let grosseListe=[];
            // on essaye de mettre en tableau 
    

            let grosseListe=[];
          // on essaye de mettre en tableau 
            for (let n=0; n<listCoach.length; n++){
                let aux ={
                        coach:listCoach[n],
                        nbVente:listVente[n],
                        facture:listFacture[n],
                        avoir:listAvoir[n],
                        total:listTotal[n],
                    }
                grosseListe.push(aux);
            }
            this.listAll=grosseListe;
           
            
            
            
        }

    },
};

//refresh list terminal
function ListRefresh(root)
{
    //refresh graph
    root.$refs.graph.frefresh( root.list_date);

    //---------------------------------
    //liste terminal
    Terminal.getAllMemory( function(fbuf)
    {
        let tab = [];
        //global terminal
        tab.push({
            value : 'all',
            text  : 'Tout',
        });

        //add terminal
        for( const i in fbuf )
        {
            //add change
            tab.push({
                value : fbuf[i].uid,
                text  : fbuf[i].mac,
            });

            //find actu
            if( root.$route.params.uid==fbuf[i].uid )
            {
                root.terminal_uid  = fbuf[i].uid;
                root.terminal_name = fbuf[i].mac;
            }
            if(root.$route.params.uid == 'all')
            {
                root.terminal_uid  = 'all';
                root.terminal_name = 'Tout';
            }
        }
        root.terminal_list = tab;
    });
}


//Facture in terminal
function frefresh(root)
{
    //---------------------------------
    //List facture
    Invoice.getAllMemory( function(fbuf)
    {
        //vider mémoire
        root.list_facture = [];

        //stats
        state(fbuf, root)

        //complete data
        function completeDate(buffer)
        {
            //filtre terminal
            if(buffer.terminal!=root.$route.params.uid )
            if(root.$route.params.uid!='all')
                return

            //filtre verif type
            if(root.list_type != "all")
            if(buffer.type != root.list_type )
                return
            
            //filtre par date
            if(root.list_date != null)
            {
                let tfmin = (new Date( root.list_date.getFullYear(), root.list_date.getMonth(), 1 )).getTime()
                let tfmax = (new Date( root.list_date.getFullYear(), root.list_date.getMonth()+1, 1 )).getTime()
                let facd  = (new Date(buffer.createAt)).getTime()
                if( facd < tfmin )
                    return
                if( facd > tfmax)
                    return
            }


            Member.getMemory( buffer.member, function(mbr)
            {
                //Si pas de membre ?
                let p_member = { first_name:'Aucun', last_name:'' };
                if(mbr)
                    p_member = mbr;
                
                //get vendor
                Member.getMemory( buffer.vendor, function(ved)
                {
                    let p_vendor = { first_name:'Aucun', last_name:'' };
                    if(ved)
                        p_vendor = ved;

                    //payement
                    let paye = []
                    if(buffer.pay_money!=0)
                        paye.push({name:'ESP', value:buffer.pay_money})

                    if(buffer.pay_cb!=0)
                        paye.push({name:'CB',value:buffer.pay_cb})

                    if(buffer.pay_check!=0)
                        paye.push({name:'CHEQ',value:buffer.pay_check})

                    if(buffer.pay_dif!=0)
                        paye.push({name:'DIFF',value:buffer.pay_dif})

                    if(buffer.pay_prele!=0)
                        paye.push({name:'PREL',value:buffer.pay_prele})

                    //Item
                    Item.getTabAllMemoryKey( buffer.uid, function(items)
                    {
                        
                        //add
                        root.list_facture.push({
                            uid      : buffer.uid,
                            type     : Invoice.getTypeName(buffer),
                            date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                            vdate    : Fdate.getFullDateMin(buffer.createAt),
                            number   : buffer.number,
                            vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                            member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                            item     : items,
                            payement : paye,
                            total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                            // todo: devise
                        });
                    })
                });
            });
        }

        //lister facture
        for( var i in fbuf )
            completeDate(fbuf[i]);
    });
}


function state( list_fac, root ){

    let date_actu = new Date(root.list_date)

    let Amin = (new Date( date_actu.getFullYear(), 0, 1, 0, 0, 0 )).getTime()
    let Amax = (new Date( date_actu.getFullYear()+1, 0, 1, 0, 0, 0 )).getTime()

    let Mmin = (new Date( date_actu.getFullYear(), date_actu.getMonth(), 1, 0, 0, 0 )).getTime()
    let Mmax = (new Date( date_actu.getFullYear(), date_actu.getMonth()+1, 1, 0, 0, 0)).getTime()

    //reset box state
    root.vente_ca_annuel = 0
    root.vente_ca_mensuel = 0
    root.vente_seance = 0
    root.vente_accesoire = 0

    for( var i in list_fac )
    {
        //get facture
        let buffer = list_fac[i]
        let total = (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele)

        let facd  = (new Date(buffer.createAt)).getTime()

        //Calcule CA anné
        if(facd>=Amin)
        if(facd<=Amax)
            root.vente_ca_annuel += total

        //Calcule CA mois
        if(facd>=Mmin)
        if(facd<=Mmax)
        {
            //CA mois
            root.vente_ca_mensuel += total
        }
    }

    //Item
    Item.getAllMemory( function(items)
    {
        for( var p in items)
        {
            let buffer = items[p]
            let itdate  = (new Date(buffer.createAt)).getTime()

            //Calcule CA mois
            if(itdate>=Mmin)
            if(itdate<=Mmax)
            if(buffer.prix!=0)
            if(buffer.tva!=0)
            if(buffer.type!='re')
            {
                let total = 0
                //Si Facture
                if( buffer.type =='fa' || buffer.type =='re' )
                    total = parseFloat((buffer.prix * ((buffer.tva/100)+1)).toFixed(2))
                    // todo : devise
                //Sinon avoir
                if( buffer.type =='av' )
                    total = parseFloat((buffer.prix * (( (buffer.tva*-1)/100)+1)).toFixed(2))
                    // todo : devise
                if( buffer.credit!=0)
                    root.vente_seance += total
                else
                    root.vente_accesoire += total

            }
        }
    })
}
</script>

<style>

</style>